<template>
    <div class="app">
        <el-dialog
                title="选择投放时段"
                :visible.sync="dialogSendVisible"
                width="30%"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <el-time-picker
                        is-range
                        v-model="time"
                        @change="orderDate" @input="immediUpdate"
                        value-format="HH:mm"
                        format="HH:mm"
                        range-separator="~"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                </el-time-picker>

            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        components: {

        },
        props: {
            type:{
                type: String,
                default: "daterange"
            }
        },
        data() {
            return {
                time:[new Date(2016, 9, 10, 12, 40), new Date(2016, 9, 10, 12, 40)],
                form: {
                    startTime: '',
                    endTime: '',
                },
                dialogSendVisible: true,
            };
        },

        methods: {
            orderDate(obj) {
                if (obj) {
                    console.log(obj)
                    this.form.startTime = obj[0];
                    this.form.endTime = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.form.startTime = '';
                        this.form.endTime = '';
                    }
                });
            },
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$emit("dialog", false);
            },
            confirm() {
                this.$parent.timerange = this.form.startTime + '~' + this.form.endTime;
                this.$parent.form.timeList = [{"startTime":this.form.startTime,"endTime":this.form.endTime}];
                this.$parent.form.timeType = 2;
                console.log(this.form)
                this.handleSendClose();
            },
        },
    };
</script>
