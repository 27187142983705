<template>
    <div class="app">
        <el-dialog
                title="选择日期区域"
                :visible.sync="dialogSendVisible"
                width="40%"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <el-date-picker
                        v-model="date"
                        @change="orderDate" @input="immediUpdate"
                        :type="type"
                        range-separator="~"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>

            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        components: {

        },
        props: {
            type:{
                type: String,
                default: "daterange"
            }
        },
        data() {
            return {
                date:'',
                form: {
                    startTime:'',
                    endTime:'',
                },
                dialogSendVisible: true,
            };
        },

        methods: {
            orderDate(obj) {
                if (obj) {
                    console.log(obj)
                    this.form.startTime = obj[0];
                    this.form.endTime = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.form.startTime = '';
                        this.form.endTime = '';
                    }
                });
            },
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$emit("dialog", false);
            },
            confirm() {
                // this.$parent.form.areaDateStart = this.form.startTime;
                // this.$parent.form.areaDateEnd = this.form.endTime;
                this.$parent.form.dateList = [{"startDate":this.form.startTime,"endDate":this.form.endTime}];
                this.$parent.form.dateType = 2;
                this.$parent.daterange = this.form.startTime + '~' + this.form.endTime;
                this.handleSendClose();
            },
        },
    };
</script>
