<template>
    <div class="app">
        <el-dialog
                title="选择日期区域"
                :visible.sync="dialogSendVisible"
                width="40%"
                :before-close="handleSendClose"
        >
            <el-timeline>
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="最小年龄" prop="min"
                              :rules="[{ required: true, message: '请填写最小年龄', trigger: 'blur' },]">
                  <el-input type="number" placeholder="请填写最小年龄" v-model="form.min"></el-input>
                </el-form-item>
                <el-form-item label="最大年龄" prop="max"
                              :rules="[{ required: true, message: '请填写最大年龄', trigger: 'blur' },]">
                  <el-input type="number" placeholder="请填写最大年龄" v-model="form.max"></el-input>
                </el-form-item>
              </el-form>

            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm('form')">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        components: {

        },
        props: {
          min:{
            type: Number,
            default: 18
          },
          max:{
            type: Number,
            default: 40
          },
        },
        data() {
            return {
                date:'',
                form: {
                    min:18,
                    max:40,
                },
                dialogSendVisible: true,
            };
        },
        created() {
          this.form.min = this.min;
          this.form.max = this.max;
        },
      methods: {
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$emit("dialog", false);
            },
            confirm(e) {
              this.$refs[e].validate((res) => {
                if (res) {

                  if(this.form.min === 0 || this.form.max === 0){
                    this.$message({
                      type: "warning",
                      message: "请填写完整信息！",
                    });
                    return false;
                  }
                  if(this.form.min > this.form.max){
                    this.$message({
                      type: "warning",
                      message: "最小年龄大于最大年龄！",
                    });
                    return false;
                  }
                  if(this.form.min < 18 || this.form.max > 68){
                    this.$message({
                      type: "warning",
                      message: "年龄范围 18~68！",
                    });
                    return false;
                  }

                  var str = this.form.min + '~' + this.form.max;
                  this.$parent.form.agerange = str;
                  this.$parent.agerange = str;
                  this.handleSendClose();
                } else {
                  this.$message({
                    type: "warning",
                    message: "请填写完整信息！",
                  });
                  return false;
                }
              });

            },
        },
    };
</script>
