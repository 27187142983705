<template>
    <div class="container">
        <div class="upload">
            <div>

            </div>
            <div>
                <el-upload class="avatar-uploader"
                           action=""
                           :http-request="authUpload"
                           :before-upload="fileChange"
                           :show-file-list="false">
                    <video v-if="showVideoPath"
                           :src="showVideoPath"
                           class="avatar video-avatar"
                           style="max-width: 200px"
                           controls="controls">
                        您的浏览器不支持视频播放
                    </video>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <div class="el-upload__tip" slot="tip">
                        <span class="progress" v-if="authProgress > 0">上传进度: <i id="auth-progress">{{authProgress}}</i> %</span><br>
                        短视频内容分辨率支持 <br>
                        540*960、720*1280、1080*1920，宽高比位9：16 <br>
                        大小不超过500MB，超过后由后台自动压缩。压缩后仍然超过则提示“视频大小超过500MB，请重新上传“ <br>
                        上传视频不超过30S <br>
                        视频格式支持：MP4、MOV、RMVB格式
                    </div>
                </el-upload>
            </div>
        </div>
    </div>
</template>
<script>
    import {baseUrl} from "@/plugins/api";
    import axios from 'axios';

    export default {
        props:{
            contents:String,
        },
        data() {
            return {
                timeout: '',
                partSize: '',
                parallel: '',
                retryCount: '',
                retryDuration: '',
                region: 'cn-shanghai',
                userId: '1191491240223974',
                file: null,
                authProgress: 0,
                uploadDisabled: true,
                resumeDisabled: true,
                pauseDisabled: true,
                uploader: null,
                statusText: '',
                time:0,
                showVideoPath:'',
            }
        },
        watch: {
            //正确给 cData 赋值的 方法
            // eslint-disable-next-line no-unused-vars
            contents: function(newVal,oldVal){
                this.showVideoPath = newVal;
            }
        },
        methods: {
            fileChange(e) {
                this.file = e
                if (!this.file) {
                    alert("请先选择需要上传的文件!")
                    return
                }
                var userData = '{"Vod":{}}'
                if (this.uploader) {
                    this.uploader.stopUpload()
                    this.authProgress = 0
                    this.statusText = ""
                }
                this.uploader = this.createUploader()
                console.log(userData)
                this.uploader.addFile(this.file, null, null, null, userData)
                this.uploadDisabled = false
                this.pauseDisabled = true
                this.resumeDisabled = true
                // this.authUpload();
            },
            authUpload() {
                // 然后调用 startUpload 方法, 开始上传
                if (this.uploader !== null) {
                    this.uploader.startUpload()
                    this.uploadDisabled = true
                    this.pauseDisabled = false
                }
            },
            // 暂停上传
            pauseUpload() {
                if (this.uploader !== null) {
                    this.uploader.stopUpload()
                    this.resumeDisabled = false
                    this.pauseDisabled = true
                }
            },
            // 恢复上传
            resumeUpload() {
                if (this.uploader !== null) {
                    this.uploader.startUpload()
                    this.resumeDisabled = true
                    this.pauseDisabled = false
                }
            },
            createUploader() {
                let self = this
                // eslint-disable-next-line no-undef
                let uploader = new AliyunUpload.Vod({
                    timeout: self.timeout || 60000,
                    partSize: self.partSize || 1048576,
                    parallel: self.parallel || 5,
                    retryCount: self.retryCount || 3,
                    retryDuration: self.retryDuration || 2,
                    region: self.region,
                    userId: self.userId,
                    // 添加文件成功
                    addFileSuccess: function (uploadInfo) {
                        self.uploadDisabled = false
                        self.resumeDisabled = false
                        self.statusText = '添加文件成功, 等待上传...'
                        self.getVideoTime(uploadInfo.file);
                        console.log("addFileSuccess: ", uploadInfo.file.name)
                    },
                    // 开始上传
                    onUploadstarted: function (uploadInfo) {
                        // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                        // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                        // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                        // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                        // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                        // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
                        if (!uploadInfo.videoId) {
                            // let createUrl = this.$api.getUploadAuth+''+uploadInfo.file.name+'/'+uploadInfo.file.name;
                            let createUrl = baseUrl + "system/file/getUploadAuth/" + uploadInfo.file.name + "/" + uploadInfo.file.name;
                            axios.get(createUrl).then(({data}) => {
                                let res = data.data;
                                let uploadAuth = res.uploadAuth
                                let uploadAddress = res.uploadAddress
                                let videoId = res.videoId;
                                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
                            })
                            self.statusText = '文件开始上传...'
                            console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                        }
                    },
                    // 文件上传成功
                    onUploadSucceed: function (uploadInfo) {
                        console.log(uploadInfo);
                        let url = self.$config.aliVodUrl + uploadInfo.object;
                        let data = {"url":url,"time":self.time,"videoId":uploadInfo.videoId};
                        console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                        self.statusText = '文件上传成功!';
                        self.showVideoPath = url;
                        self.$emit("get-upload-video", data);
                    },
                    // 文件上传失败
                    onUploadFailed: function (uploadInfo, code, message) {
                        console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                        self.statusText = '文件上传失败!'
                    },
                    // 取消文件上传
                    onUploadCanceled: function (uploadInfo, code, message) {
                        console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
                        self.statusText = '文件已暂停上传'
                    },
                    // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                    onUploadProgress: function (uploadInfo, totalSize, progress) {
                        console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                        let progressPercent = Math.ceil(progress * 100)
                        self.authProgress = progressPercent
                        self.statusText = '文件上传中...'
                    },
                    // 上传凭证超时
                    // eslint-disable-next-line no-unused-vars
                    onUploadTokenExpired: function (uploadInfo) {
                        // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                        // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                        // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
                        /*let refreshUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=' + uploadInfo.videoId
                        axios.get(refreshUrl).then(({data}) => {
                            let uploadAuth = data.UploadAuth
                            uploader.resumeUploadWithAuth(uploadAuth)
                            console.log('upload expired and resume upload with uploadauth ' + uploadAuth)
                        })
                        self.statusText = '文件超时...'*/
                    },
                    // 全部文件上传结束
                    // eslint-disable-next-line no-unused-vars
                    onUploadEnd: function (uploadInfo) {
                        console.log("onUploadEnd: uploaded all the files")
                        self.statusText = '文件上传完毕'
                    }
                })
                return uploader
            },
            getVideoTime(file) {
                let _this = this;
                var url = URL.createObjectURL(file);
                var audioElement = new Audio(url);
                var duration;
                audioElement.addEventListener("loadedmetadata", function (_event) {
                    duration = audioElement.duration; //时长为秒，小数，182.36
                    _this.time = duration;
                    console.log(duration, "获取时长", _event, audioElement)
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .upload-type-switch {
        text-align: center;
    }

    .container {
        width: 1200px;
        margin: 0 auto;
    }

    .input-control {
        margin: 5px 0;
    }

    .input-control label {
        font-size: 14px;
        color: #333;
        width: 30%;
        text-align: right;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }

    .input-control input {
        width: 30%;
        height: 30px;
        padding: 0 5px;
    }

    .progress {
        font-size: 14px;
    }

    .progress i {
        font-style: normal;
    }

    .upload-type {
        color: #666;
        font-size: 12px;
        padding: 10px 0;
    }

    .upload-type button {
        margin: 0 10px 0 20px;
    }

    .status {
        font-size: 14px;
        margin-left: 30px;
    }

    .info {
        font-size: 14px;
        padding-left: 30px;
    }
</style>
