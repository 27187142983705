<template>
    <div class="app">
        <el-dialog
                title="选择地域"
                :visible.sync="dialogSendVisible"
                width="600px"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <div id="app">
                    <ul class="areaUl">
                        <li class="areaLi" style="border-bottom:1px #f5f5f5 solid">
                            <ul class="planUl">
                                <li class="planLi vote-item">
                                    <label class="vote-more-checkbox">
                                        <span class="check-title">不限</span>
                                        <input type="checkbox" value="-1" v-model="all.province">
                                        <span class="checkbox-icon">
                                            <i style="width: 5px; height: 10px;"></i>
                                          </span>
                                    </label>
                                </li>
                                <li class="planLi vote-item"
                                    :class="{active: currentIndex === index}"
                                    v-for="(items,index) in areaList"
                                    :key="index" :value="items.code"
                                    @click="province(items,index)">
                                    <label class="vote-more-checkbox">
                                        <span class="check-title">{{items.name}}</span>
                                        <input type="checkbox" :value="items" v-model="form.province"/>
                                    </label>
                                </li>
                            </ul>
                        </li>
                        <li class="areaLi" style="border-bottom:1px #f5f5f5 solid">
                            <ul class="planUl">
                                <li class="planLi vote-item">
                                    <label class="vote-more-checkbox">
                                        <span class="check-title">不限</span>
                                        <input type="checkbox" value="-1" v-model="all.city">
                                        <span class="checkbox-icon">
                                            <i style="width: 5px; height: 10px;"></i>
                                          </span>
                                    </label>
                                </li>
                                <li class="planLi vote-item"
                                    :class="{active: currentIndex2 === index}"
                                    v-for="(items,index) in cityList"
                                    :key="index" :value="items.code"
                                    @click="city(items,index)">
                                    <label class="vote-more-checkbox">
                                        <span class="check-title">{{items.name}}</span>
                                        <input type="checkbox" :value="items"
                                               v-model="form.city">
                                    </label>
                                </li>
                            </ul>
                        </li>
                        <li class="areaLi" style="border-left:1px #f5f5f5 solid">
                            <ul class="planUl">
                                <li class="planLi vote-item">
                                    <label class="vote-more-checkbox">
                                        <span class="check-title">全选</span>
                                        <input type="checkbox" @click="checkedAll" value="-1" v-model="all.region">
                                        <span class="checkbox-icon">
                                            <i style="width: 5px; height: 10px;"></i>
                                          </span>
                                    </label>
                                </li>
                                <li class="planLi vote-item"
                                    :class="{active: currentIndex3 === index}"
                                    v-for="(items,index) in regionList"
                                    :key="index" :value="items.code">
                                    <label class="vote-more-checkbox"
                                           @change="region(items,index)">
                                        <span class="check-title">{{items.name}}</span>
                                        <input type="checkbox" :value="items"
                                               v-model="form.region">
                                        <span class="checkbox-icon">
                                            <i style="width: 5px; height: 10px;"></i>
                                          </span>
                                    </label>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="mask" v-if="showChecked" @click="showChecked = false">
                        <div class="poper" v-if="showChecked">
                            <div class="total l">已选区县：{{selectData.length}}</div>
                            <div class="clear cl"><span class="regionNum" @click.stop="deleteAll">清空全部</span></div>
                            <div class="cityList">
                                <div v-for="(items,i) in data" :key="i" style="display:block;clear: both;">
                                    <div class="city" v-for="(city,j) in items.children" :key="j">
                                        <span>{{city.name}}</span>
                                        <ul>
                                            <li v-for="(reg,index) in city.children" :key="index">
                                                <el-button size="medium" round>
                                                    {{reg.name}}
                                                    <i class="el-icon-circle-close" @click.stop="del(reg)"></i>
                                                </el-button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <div @click="showMask" style="display: block;float: left;">
                    已选结果 <span class="regionNum">{{selectData.length}}</span>
                </div>
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {},
        props:{
            selectArea:{}
        },
        data() {
            return {
                showChecked: false,
                dialogSendVisible: true,
                currentIndex: '',
                currentIndex2: '',
                currentIndex3: '',
                areaList: [],
                cityList: [],
                regionList: [],
                form: {
                    province: [],
                    city: [],
                    region: [],
                },
                all: {
                    province: false,
                    city: false,
                    region: false,
                },
                checkedList: {
                    province: -1,
                    city: -1,
                    region: -1,
                },
                data: [],
                selectData: [],
            };
        },
        mounted() {
            this.init();
        },
        created() {
            this.selectData = this.selectArea;
            console.log(this.selectData,"this.selectData")
        },
        methods: {
            init() {
                var list = [];
                this.$post(this.$api.provinceAll, {type: "2"}).then((res) => {
                    if (res.code == 1) {
                        res.data.forEach((items, index) => {
                            var obj = {
                                name: items.name,
                                code: items.code,
                                pcode: items.pcode,
                                children: (items.children && items.children.length > 0) ? this.formdata(index, items.children) : [],
                                isChecked: false,
                                id: index,
                                pid: 0,
                            };
                            list.push(obj);
                        });
                        this.areaList = list;
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });

            },
            formdata(p1, res) {
                var list = [];
                res.forEach((items, index) => {
                    var obj = {
                        name: items.name,
                        code: items.code,
                        pcode: items.pcode,
                        children: (items.children && items.children.length > 0) ? this.formdata2(p1 + '-' + index, items.children) : [],
                        isChecked: false,
                        id: p1 + '-' + index,
                        pid: p1,
                    };
                    list.push(obj);
                });
                return list;
            },
            formdata2(p2, res) {
                var list = [];
                res.forEach((items, index) => {
                    var obj = {
                        name: items.name,
                        code: items.code,
                        pcode: items.pcode,
                        children: [],
                        isChecked: false,
                        id: p2 + '-' + index,
                        pid: p2,
                    };
                    list.push(obj);
                });
                return list;
            },
            province(items, index) {
                this.currentIndex = index;
                // this.all.city = 1;
                if (this.areaList[index].isChecked) {
                    this.areaList[index].isChecked = false;
                } else {
                    this.areaList[index].isChecked = true;
                }
                this.cityList = items.children;
                this.regionList = [];
            },
            city(items, index) {
                this.currentIndex2 = index;
                if (this.cityList[index].isChecked) {
                    this.cityList[index].isChecked = false;
                } else {
                    this.cityList[index].isChecked = true;
                }
                this.regionList = items.children;
                this.all.region = false;
            },
            region(items, index) {
                this.currentIndex3 = index;
                if (this.regionList[index].isChecked) {
                    this.regionList[index].isChecked = false;
                    this.selectData.forEach((item, i) => {
                        if (item.id === this.regionList[index].id) {
                            this.selectData.splice(i, 1);
                        }
                    })
                    console.log(this.selectData, "selectData false")
                } else {
                    this.regionList[index].isChecked = true;
                    this.selectData.push(this.regionList[index]);
                    console.log(this.selectData, "selectData true")
                }
                this.showChecked = false;
                console.log(this.selectData, "selectData")
                // this.getLastData3();
            },

            handleSendClose() {
                this.dialogSendVisible = false;
                this.$emit("dialog", false);
            },
            confirm() {
                this.handleSendClose();
                this.$parent.form.areaList = this.data;
                this.$parent.form.selectArea = JSON.stringify(this.selectData);
                this.$parent.areaList = 3;
                this.$parent.arearange = "已选择（" + this.selectData.length + ")";
            },
            getLastData3() {
                var list = this.getCheckList(this.selectData);
                if (list.length === 0) {
                    this.all.region = true;
                } else {
                    this.all.region = false;
                }
                this.data = list;
                return list;
            },
            getCheckList(item) {
                var list = [];
                if (item.length === 0) {
                    return [];
                }
                item.forEach((items) => {
                    var arr = items.id.split("-");
                    var children = this.setTree2(this.areaList[arr[0]].children[arr[1]], this.areaList[arr[0]].children[arr[1]].children[arr[2]]);
                    var newArea = {
                        name: this.areaList[arr[0]].name,
                        code: this.areaList[arr[0]].code,
                        pcode: this.areaList[arr[0]].pcode,
                        children: children,
                        id: this.areaList[arr[0]].id,
                    };

                    list.push(newArea);
                });
                return this.setTree(list);
            },
            // eslint-disable-next-line no-unused-vars
            setTree2(item, p3) {
                var newArea = [{
                    name: item.name,
                    code: item.code,
                    pcode: item.pcode,
                    children: this.setTree3(p3),
                    id: item.id,
                }];
                return newArea;
            },
            setTree3(item) {
                let data = [];
                // eslint-disable-next-line no-unused-vars
                this.selectData.forEach((arr, index) => {
                    if (item.pid === arr.pid) {
                        data.push(arr);
                    }
                });
                return data;
            },
            setTree(list) {
                // eslint-disable-next-line no-debugger
                // debugger
                let newArr = [];
                let obj = {};
                if (list.length === 0) {
                    return [];
                }
                if (list.length === 1) {
                    return list;
                }
                //获取第一层
                for (var i = 0; i < list.length; i++) {
                    if (!obj[list[i].code]) {
                        list[i].children = [];
                        newArr.push(list[i]);
                        obj[list[i].code] = true;
                    }
                }

                newArr.forEach((res, index1) => {
                    list.forEach((res2) => {
                        if (res.code === res2.code) {
                            console.log("省份", res.name, res.id)
                            // eslint-disable-next-line no-unused-vars
                            res2.children.forEach((res3, index3) => {
                                if (!obj[res3.code]) {
                                    newArr[index1].children.push(res3);
                                    obj[res3.code] = true;
                                }
                            })
                        }
                    });
                });
                return newArr;
            },
            del(reg) {
                if (reg) {
                    this.selectData.forEach((item, index) => {
                        if (reg.id === item.id) {
                            console.log(reg.name, item.name);
                            // this.form.region[index].isChecked = false;
                            this.selectData.splice(index, 1)
                            this.getLastData3();
                        }
                    })
                }
            },
            deleteAll() {
                this.$confirm('确定全部删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.selectData.forEach((item, index) => {
                        this.selectData.splice(index, 10)
                        this.getLastData3();
                    })
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.hideMask();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            showMask() {
                this.showChecked = true;
                this.getLastData3();
            },
            hideMask() {
                this.showChecked = false;
            },
            checkedAll() {
                var _this = this;
                if (_this.all.region) { //实现反选
                    _this.regionList.forEach((item) => {
                        _this.selectData.forEach(function (res, i) {
                            if (item.id === res.id) {
                                _this.selectData.splice(i, 1)
                            }
                        })
                    })
                    _this.form.region = [];
                } else { //实现全选
                    _this.form.region = [];
                    _this.regionList.forEach(function (item) {
                        _this.form.region.push(item);
                        _this.selectData.push(item);
                    });
                }
                this.getLastData3();
                console.log(_this.selectData, _this.selectData.length, "region")
            }
        }
    }
</script>

<style lang="scss" scoped>
    #app {
        display: block;
        clear: both;
        position: relative;

        .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .3);
        }
    }

    .poper {
        position: fixed;
        bottom: 130px;
        width: 560px;
        height: 360px;
        background: #FFFFFF !important;
        opacity: 1 !important;
        font-size: 15px;
        padding-top: 14px;
        border-bottom: 1px #f5f5f5 solid;

        .total, .clear {
            display: inline-block;
            width: 50%;
        }

        .cityList {
            overflow-y: auto;
            height: 310px;

            .city {
                span {
                    display: block;
                    padding: 20px 20px 10px 0px;
                }

                ul {
                    display: flow-root;
                    clear: both;

                    li {
                        display: block;
                        /*width: 25%;*/
                        height: 36px;
                        float: left;
                        margin: 5px;

                        .el-button--medium.is-round {
                            position: relative;

                            i {
                                position: absolute;
                                right: -6px;
                                top: -6px;
                                font-size: 20px;
                                border: #FFFFFF 1px solid;
                            }
                        }
                    }
                }
            }
        }
    }

    .cl {
        text-align: right;
    }

    .l {
        float: left;
    }

    .r {
        float: right;
    }

    ul {
        padding: 0;
        margin: 0;
        display: flow-root;
    }

    ul li {
        list-style: none;
    }

    .areaUl .areaLi {
        width: 33%;
        height: 60vh;
        overflow-y: auto;
        line-height: 40px;
        float: left;
        list-style: none;
        background-color: #FFFFFF;
    }

    /*Width*/
    ::-webkit-scrollbar {
        width: 1px;
        height: 1px;
    }


    /*Track*/
    ::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    /*Handle*/
    ::-webkit-scrollbar-thumb {
        background-color: #FFFFFF;
        border-radius: 10px;
    }

    .planUl .planLi {
        height: 48px;
    }

    .active {
        background-color: #F5F5F5;
        color: #F5222D;
    }

    .vote-item {
        width: 100%;
        height: 48px;
        line-height: 48px;
        /*margin-bottom: 0.3rem;*/
        .vote-more-checkbox {
            height: 48px;
            display: flex;
            justify-content: space-between;
            line-height: 48px;
            padding-left: 40px;
            position: relative;

            .check-title {
                font-size: 15px;
                color: #000000;
            }

            .checkbox-icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                border: 1 solid #EBEBEB;
                border-radius: 25px;
                vertical-align: middle;
                pointer-events: none;
                color: #0086F2;
                margin-top: 13px;
                position: absolute;
                left: 10px;

                i {
                    content: '';
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    border: 3px solid #ffffff;
                    border-top: 0;
                    border-left: 0;
                    transform: translate(-50%, -50%) rotate(45deg) scale(0);
                }
            }

        }
    }

    input[type=checkbox] {
        position: absolute;
        left: -9999em;
    }

    input[type=checkbox]:checked + .checkbox-icon {
        background-color: #F5222D;
        border-color: #F5222D;
    }

    input[type=checkbox] + .checkbox-icon {
        background-color: #FFFFFF;
        border: 1px solid #EBEBEB;
    }

    input[type=checkbox]:checked + .checkbox-icon > i {
        transform: translate(-50%, -50%) rotate(45deg) scale(1);
        transition: all .2s ease-in-out;
    }

    .regionNum {
        color: #F5222D;
    }

</style>
